#ReportContainer {
  margin-top: -10px;
  height: 30px;
  /* margin-left: 8px; */
  /* margin-right: 8px; */
  display: flex;
  align-items: center;
}

#ReportContainerOptions {
  display: flex;
  /* margin-right: 10px;
  padding-right: 5px; */
  /* border-right: 1px solid #cecece; */
  align-items: center;
}

.imageButtonReport {
  height: 15px;
  width: 15px;
  fill: white;
}

@media (max-width: 600px) {
  #ReportContainer {
    justify-content: space-between;
  }

  #ReportContainerOptions {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  .imageButtonReport {
    height: 15px;
    width: 15px;
  }
}

.report {
  margin-top: 0px;
  height: calc(100dvh - 300px);
  min-height: 100%;
}
.topbar {
  height: 30px;
  display: flex;
  align-items: center;
}
.topbar a {
  color: var(--color-white);
  font-size: 18px;
  padding: 0 20px;
}

#reportRangeContainer {
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding-right: 2rem;
  transition: width 0.3s ease;
}

#reportRange {
  width: 150px;
  margin: 0 5px;
  padding: 0 5px;
  accent-color: #2ecc71
}

#reportRangeValue {
  margin: 0 10px;
}

.zoomReportButton {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.zoomReportButton:hover {
  opacity: 0.5;
  transition: 0.5s;
}

@media (max-width: 900px) {
  #reportRangeContainer {
    padding-right: 1rem;
  }
}

.containerReport {
  position: relative;
  display: block;
  /* min-height: calc(100vh - 90px); */
}

.menuLeft {
  width: 250px;
  padding: 13px;
  border-right: 2px solid var(--color-grey);
}

.usersGroup {
  padding: 4px;
  font-weight: bold;
}

.usersReport {
  padding: 4px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
}
